//
// Themes / Clean / Main navbar
// --------------------------------------------------


#main-navbar {
  // Header
  //

  .navbar-header a {
    color: $navbar-text-color;
  }


  // Collapse
  //

  .navbar-header,
  .navbar-collapse {
    background: $navbar-color;
    @include box-shadow(none);
  }

  .navbar-collapse {
    border-top: 1px solid lighten($navbar-color, 8%);
  }

  .dropdown > a,
  .dropdown-menu > li > a,
  .nav > li > a {
    color: $navbar-text-color;
  }


  // Navbar buttons
  //

  .navbar-toggle {
    color: $navbar-text-color;
  }

  #main-menu-toggle {
    background: $navbar-color;
    color: $navbar-text-color;

    .fa:before {
      color: $navbar-text-color;
    }
  }

  // Navbar Items
  //

  li + li,
  .nav + .nav,
  .nav + .right {
    border-top: 1px solid lighten($navbar-color, 8%);
  }

  .dropdown.open > a {
    border-top: 1px solid darken($navbar-color, 8%);
    border-bottom: 1px solid darken($navbar-color, 5%);
    margin-top: -1px;
    background: darken($navbar-color, 3%);
  }


  // Navbar forms
  //

  form.navbar-form {
    @include box-shadow(none);

    .form-control {
      background: #fff;
      border-color: $navbar-color;
      border-radius: 999999px;
    }
  }
}


// Desktops
//

@media (min-width: $screen-tablet) {
  #main-navbar {

    // Navbar Items
    //

    li + li,
    .nav + .nav,
    .nav + .right {
      border-top: none;
    }


    // Collapse
    //

    .navbar-collapse {
      border-top: none;
    }


    // Dropdowns
    //

    .nav > li > a {
      &:hover {
        background: darken($navbar-color, 3%);
      }
    }

    .dropdown.open > a {
      border-top: none;
      border-bottom: none;
      margin-top: 0;
      background: darken($navbar-color, 3%);
    }

    .dropdown-menu {
      border-radius: $border-radius-base;
      margin-top: 3px;
      padding: 5px 0;

      > li > a {
        color: $text-color;

        &:hover {
          background: #f6f6f6;
        }
      }
    }
  }
}

