//
// Themes / White / Main menu
// --------------------------------------------------


// Background
//

#main-menu-bg,
.mmc-dropdown-open-ul {
  background: $menu-color;
}


// Main Menu
//

#main-menu {
  #main-menu-inner {
    padding-top: 10px;
  }

  .navigation,
  .mmc-dropdown-open-ul {

    li {
      a {
        color: $menu-text-color;
        @include transition(all .2s);

        &:hover {
          color: darken($menu-text-color, 30%);
        }
      }

      &.active > a {
        color: darken($menu-text-color, 30%);
        background: #f6f6f6;
      }
    }
  }

  .mm-dropdown.active > a {
    background: none !important;
  }


  // Dropdowns
  //

  .mmc-dropdown-open > a {
    color: darken($menu-text-color, 30%) !important;
  }

  .mmc-title {
    color: darken($menu-text-color, 30%);
    background: $menu-color;
  }

  .mmc-dropdown-open-ul {
    border: 1px solid $page-header-border-color;
    margin-top: -1px;
  }

  // Main menu content blocks
  .menu-content {
    border-color: $page-header-border-color;

    .btn-outline {
      border-color: $page-header-border-color;
      color: $menu-text-color;

      &:hover,
      &:active {
        border-color: transparent;
        color: #fff;
      }
    }

    &.top {
      margin-top: -10px;
    }
  }

}


// Dropdown highlights
//

// Highlight opened dropdown
.mmc-dropdown-open .mm-dropdown.open > a,
&.mme #main-menu .mm-dropdown.open > a {
  color: darken($menu-text-color, 30%) !important;
}

@media (min-width: $screen-tablet) {
  // Highlight expanded submenus
  #main-menu .mm-dropdown.open > a {
    color: darken($menu-text-color, 30%);
  }

  // Unhighlight expanded submenus in collapsed mode
  &.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: $menu-text-color;
  }
}


// Menu border
//

#main-menu-bg,
#main-menu .navigation li.active > a,
&.right-to-left.main-menu-right #main-menu-bg,
&.right-to-left.main-menu-right #main-menu .navigation li.active > a {
  border-left: none;
  border-right: 1px solid $page-header-border-color;
}

#main-menu .mmc-dropdown-open-ul,
&.right-to-left.main-menu-right #main-menu .mmc-dropdown-open-ul {
  margin-left: -1px !important;
  margin-right: 0 !important;
}

&.right-to-left #main-menu-bg,
&.right-to-left #main-menu .navigation li.active > a,
&.main-menu-right #main-menu-bg,
&.main-menu-right #main-menu .navigation li.active > a {
  border-left: 1px solid $page-header-border-color;
  border-right: none;
}

&.right-to-left #main-menu .mmc-dropdown-open-ul,
&.main-menu-right #main-menu .mmc-dropdown-open-ul {
  margin-left: 0 !important;
  margin-right: -1px !important;
}