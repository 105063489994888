//
// Themes / Clean / Main menu
// --------------------------------------------------


// Background
//

#main-menu-bg,
.mmc-dropdown-open-ul {
  background: $menu-color;
}


// Main Menu
//

#main-menu {
  #main-menu-inner {
    padding-top: 10px;
  }

  .navigation,
  .mmc-dropdown-open-ul {
    li {
      a {
        color: $menu-text-color;
        font-size: 12px;
        border-bottom: 1px solid darken($menu-color, 5%);
        @include transition(all .2s);

        &:hover {
          color: darken($menu-text-color, 30%);
        }
      }

      &.active > a {
        color: darken($menu-text-color, 30%);
        background: $body-color;
      }
    }

    > li:first-child {
      border-top: 1px solid darken($menu-color, 5%);
    }
  }

  .mm-dropdown.active > a {
    background: none !important;
  }


  // Dropdowns
  //

  .mmc-dropdown-open > a {
    color: darken($menu-text-color, 30%) !important;
  }

  .mmc-title {
    color: darken($menu-text-color, 30%);
    background: $menu-color;
    border-bottom: 1px solid darken($menu-color, 5%);
  }

  .mmc-dropdown-open-ul {
    border: 1px solid $page-header-border-color;
    margin-top: -1px;
  }

  .mmc-dropdown-open-ul.top .mmc-title {
    border-bottom: none;
  }

  // Main menu content blocks
  .menu-content {
    border: none;
    margin-top: -10px;

    .btn-outline {
      border-color: darken($menu-color, 5%);
      color: $menu-text-color;

      &:hover,
      &:active {
        border-color: transparent;
        color: #fff;
      }
    }
  }
}


// Dropdown highlights
//

// Highlight opened dropdown
.mmc-dropdown-open .mm-dropdown.open > a,
&.mme #main-menu .mm-dropdown.open > a {
  color: darken($menu-text-color, 30%) !important;
}

@media (min-width: $screen-tablet) {
  // Highlight expanded submenus
  #main-menu .mm-dropdown.open > a {
    color: darken($menu-text-color, 30%);
  }

  // Unhighlight expanded submenus in collapsed mode
  &.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: $menu-text-color;
  }
}


// Menu border
//

#main-menu-bg,
&.right-to-left.main-menu-right #main-menu-bg {
  border-left: none;
  border-right: 1px solid darken($menu-color, 5%);
}

#main-menu .mmc-dropdown-open > ul,
&.right-to-left.main-menu-right #main-menu .mmc-dropdown-open > ul {
  margin-left: -1px;
  margin-right: 0;
}

&.right-to-left #main-menu-bg,
&.main-menu-right #main-menu-bg {
  border-left: 1px solid darken($menu-color, 5%);
  border-right: none;
}

&.right-to-left #main-menu .mmc-dropdown-open > ul,
&.main-menu-right #main-menu .mmc-dropdown-open > ul {
  margin-left: 0;
  margin-right: -1px;
}