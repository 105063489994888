//
// Themes / Clean / Pages
// --------------------------------------------------

// Sign In Page
&.page-signin .signin-info,
&.page-signup .signup-header {
  background: $menu-color;
  background: fade-out($navbar-color, .2);
}


// Timeline
//

.timeline:before,
.timeline:after,
.tl-header,
.tl-icon {
  background: darken($body-color, 10%);
}

.tl-header,
.tl-icon {
  box-shadow: 0 0 0 4px $body-color;
}

.tl-entry:before {
  background: $primary-color;
  box-shadow: 0 0 0 3px $body-color;
}

.tl-body:before {
  border-color: transparent $theme-border-color transparent transparent;
}

@media (min-width: $screen-tablet) {
  .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent $theme-border-color;
  }

  &.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent $theme-border-color transparent transparent;
  }
}

&.right-to-left .tl-body:before {
  border-color: transparent transparent transparent $theme-border-color;
} 


// Mail
//

&.page-mail {
  .mail-nav,
  .mail-nav:before {
    background: $body-color;
  }

  .mail-nav .sections li.active {
    .label,
    .badge {
      background: $primary-color;
    }
  }

  .mail-nav,
  .mail-nav .compose-btn,
  .mail-nav .sections li.active,
  .mail-nav:before {
    border-color: $theme-border-color;
  }

  .mail-nav .navigation.open .sections li.active {
    background: darken($body-color, 4%);
  }

  .mail-nav li.divider {
    background: $theme-border-color;
  }

  .mail-nav .sections {
    li.active {
      a {
        color: darken($body-color, 70%);
      }

      &:after {
        color: darken($body-color, 30%);
      }
    }

    a {
      color: darken($body-color, 55%);
    }
  }

  .mail-nav-header {
    color: darken($body-color, 30%);
  }

  .mail-nav .add-more a {
    color: darken($body-color, 30%);
  }
}

@media (min-width: $screen-md) {
  &.page-mail .mail-nav .navigation .sections li.active {
    background: darken($body-color, 4%);
  }

  &.page-mail .mail-nav .sections a:hover {
    color: darken($body-color, 70%);
  }
}
