//
// Themes / Frost / Main menu
// --------------------------------------------------


// Background
//

#main-menu-bg,
.mmc-dropdown-open-ul {
  background: $menu-color;
}


// Main Menu
//

#main-menu {
  .navigation,
  .mmc-dropdown-open-ul {

    li {
      a {
        color: lighten($menu-color, 36%);
        @include transition(all .1s);

        &:hover {
          color: #fff;
        }
      }

      &.active > a {
        color: #fff;
        background: darken($menu-color, 5%);
      }
    }

    > .mm-dropdown.open {
      border-bottom: 1px solid $menu-color;
    }
  }

  .mm-dropdown.active > a {
    background: none !important;
    color: #fff !important;

    &:after {
      display: none !important;
    }
  }


  // Submenus
  //

  .mm-dropdown > ul,
  .mmc-dropdown-open-ul {
    background: darken($menu-color, 5%);

    li.active > a {
      background: darken($menu-color, 8%);
    }
  }


  // Dropdowns
  //

  .mmc-dropdown-open > a {
    color: #fff !important;
  }

  .mmc-title {
    color: #fff;
    background: $menu-color;
  }

  // Main menu content blocks
  .menu-content {
    border-color: lighten($menu-color, 5%);

    .btn-outline {
      border-color: lighten($menu-color, 5%);
      color: lighten($menu-color, 36%);

      &:hover {
        color: #fff;
      }
    }
  }
}


// Dropdown highlights
//

// Highlight opened dropdown
.mmc-dropdown-open .mm-dropdown.open > a,
&.mme #main-menu .mm-dropdown.open > a {
  color: #fff !important;
}

@media (min-width: $screen-tablet) {
  // Highlight expanded submenus
  #main-menu .mm-dropdown.open > a {
    color: #fff;
  }

  // Unhighlight expanded submenus in collapsed mode
  &.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: lighten($menu-color, 35%);
  }
}


// Active item border
//

#main-menu .navigation li.active > a,
&.right-to-left.main-menu-right #main-menu .navigation li.active > a {
  position: relative;

  &:after {
    content: "";
    top: 0;
    bottom: 0;
    width: 4px;
    position: absolute;
    right: auto;
    left: 0;
    background: $navbar-color;
  }
}

&.main-menu-right #main-menu .navigation li.active > a:after,
&.right-to-left #main-menu .navigation li.active > a:after {
  left: auto;
  right: 0;
}
