//
// Themes / White / Demo
// --------------------------------------------------

.demo-logo {
  background: $primary-color;
}


#main-navbar .navbar-brand div {
  background: lighten($primary-color, 2%);
}


#main-navbar .navbar-header a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}
