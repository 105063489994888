//
// Themes / Asphalt / Main menu
// --------------------------------------------------

#main-menu-bg {
  // Body/Menu background
  background-color: $menu-color;
}

#main-menu {
  .navigation a,
  .mmc-dropdown-open-ul a {
    color: lighten($menu-color, 40%);
    position: relative;
    @include transition(all .2s);

    &:after {
      content: "";
      top: 0;
      bottom: 0;
      width: 4px;
      position: absolute;
      right: auto;
      background: darken($header-color, 5%);
      display: none;
      left: 0;
    }

    &:hover {
      color: #fff;

      &:after {
        display: block !important;
      }
    }
  }

  li.active > a {
    background: darken($menu-color, 5%);
    color: #fff;

    & .menu-icon {
      color: $header-color;
    }

    &:after {
      display: block;
    }
  }

  .mm-dropdown.active {
    & > a {
      background: none;
    }

    & > a:after {
      display: none;
    }
  }

  .mm-dropdown > ul,
  .mmc-dropdown-open-ul {
    background: lighten($menu-color, 3%);
  }

  .mmc-dropdown-open-ul .mmc-title {
    background: darken($header-color, 5%);
    color: #fff;
  }

  .mmc-dropdown-open {
    > a {
      background: darken($header-color, 5%) !important;
      
      &,
      .menu-icon {
        color: #fff !important;
      }

      &:hover:after {
        display: none !important;
      }
    }
  }

  // Main menu content blocks
  .menu-content {
    border-color: lighten($menu-color, 5%);

    .btn-outline {
      border-color: lighten($menu-color, 5%);
      color: lighten($menu-color, 40%);

      &:hover {
        color: #fff;
      }
    }
  }
}

// Dropdown highlights
//

// Highlight opened dropdown
.mmc-dropdown-open .mm-dropdown.open > a,
&.mme #main-menu .mm-dropdown.open > a {
  color: #fff;
}

@media (min-width: $screen-tablet) {
  // Highlight expanded submenus
  #main-menu .mm-dropdown.open > a {
    color: #fff;
  }

  // Unhighlight expanded submenus in collapsed mode
  &.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: lighten($menu-color, 40%);
  }
}

&.right-to-left {
  #main-menu .navigation a:after {
    left: auto;
    right: 0;
  }
}