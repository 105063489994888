//
// Themes / Frost / Pages
// --------------------------------------------------

// Sign In Page
&.page-signin .signin-info,
&.page-signup .signup-header {
  background: $navbar-color;
  background: fade-out($navbar-color, .2);
}


// Timeline
//

.timeline:before,
.timeline:after,
.tl-header,
.tl-icon {
  background: darken($body-color, 5%);
}

.tl-header,
.tl-icon {
  box-shadow: 0 0 0 4px $body-color;
}

.tl-entry:before {
  background: $primary-color;
  box-shadow: 0 0 0 3px $body-color;
}

.tl-body:before {
  border-color: transparent $theme-border-color transparent transparent;
}

@media (min-width: $screen-tablet) {
  .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent $theme-border-color;
  }

  &.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent $theme-border-color transparent transparent;
  }
}

&.right-to-left .tl-body:before {
  border-color: transparent transparent transparent $theme-border-color;
} 


// Mail
//

&.page-mail {
  .mail-nav,
  .mail-nav:before {
    background: $body-color;
  }

  .mail-nav .sections li.active {
    .label,
    .badge {
      background: $primary-color;
    }
  }

  .mail-nav,
  .mail-nav .compose-btn,
  .mail-nav .sections li.active,
  .mail-nav:before {
    border-color: $theme-border-color;
  }

  .mail-nav .navigation.open .sections li.active {
    background: desaturate(darken($body-color, 3%), 10%);
  }

  .mail-nav li.divider {
    background: $theme-border-color;
  }

  .mail-nav .sections {
    li.active {
      a {
        color: desaturate(darken($body-color, 70%), 70%);
      }

      &:after {
        color: desaturate(darken($body-color, 30%), 70%);
      }
    }

    a {
      color: desaturate(darken($body-color, 55%), 70%);
    }
  }

  .mail-nav-header {
    color: desaturate(darken($body-color, 30%), 70%);
  }

  .mail-nav .add-more a {
    color: desaturate(darken($body-color, 30%), 70%);
  }
}

@media (min-width: $screen-md) {
  &.page-mail .mail-nav .navigation .sections li.active {
    background: desaturate(darken($body-color, 3%), 10%);
  }

  &.page-mail .mail-nav .sections a:hover {
    color: desaturate(darken($body-color, 70%), 70%);
  }
}
