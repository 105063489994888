//
// Themes / Silver / Demo
// --------------------------------------------------

.demo-logo,
#main-navbar .navbar-brand div {
  background: $primary-color;
}


#main-navbar .navbar-header a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}
