//
// Themes / Adminflare / Main navbar
// --------------------------------------------------

// ************************************************************************* //
//**   Small screens (mobile first)                                        **//


// Navbar backgrounds
//

.gt-ie9 & #main-navbar,
.gt-ie9 & #main-navbar .navbar-header {
  @include gradient-vertical(#52aded, #418ce4);
}

// Use image for bg on IE 8-9
.ie8 & #main-navbar,
.ie9 & #main-navbar,
.ie8 & #main-navbar .navbar-header,
.ie9 & #main-navbar .navbar-header {
  background-image: url("#{$images-path}/themes/adminflare/ie-navbar-bg.png");
}


// Navbar
//

#main-navbar {
  border-bottom: 1px solid #185aa7;

  &,
  .navbar-header {
    background: #418ce4;
    @include box-shadow(rgba(255, 255, 255, 0.25) 0px 1px 0px inset);
  }


  // Navbar header
  //

  .navbar-brand {
    color: #fff;
  }

  .dropdown > a,
  .dropdown-menu > li > a,
  .nav > li > a {
    text-shadow: rgba(0, 0, 0, 0.2) 0px -1px 0;
    color: #fff;
  }


  // Navbar buttons
  //

  .navbar-toggle,
  #main-menu-toggle {
    color: #fff;
  }

  #main-menu-toggle {
    border: 0px solid;
    border-color: #3470b6;
    border-color: rgba(0,0,0,.2);

    .fa:before {
      color: #fff;
    }
  }


  // Navbar collapse
  //

  .main-navbar-collapse {
    border-top: 1px solid #185aa7;
    background: #418ce4;
    @include box-shadow(rgba(255, 255, 255, 0.18) 0px 1px 0px inset);
  }

  
  // Navbar Items
  //

  li {
    border-bottom: 1px solid #5697e4;
  }


  // Dropdowns
  //

  .dropdown.open > a {
    background: #377bca;
    border-bottom: 1px solid #2e6db7;
    border-top: 1px solid #306cb3;
    margin-top: -1px;
  }


  // Navbar forms
  //

  form.navbar-form {
    @include box-shadow(none);

    .form-control {
      background: #fff;
      background: rgba(255, 255, 255, 0.2);
      border: none;
      color: #fff;
      border-radius: 999999px;
      @include box-shadow(0 -1px 0 rgba(0, 0, 0, 0.2));

      &:focus {
        background: #fff;
        color: $text-color;
      }

      @include placeholder(#2565af);
    }
  }

}

// ************************************************************************* //
//**   Desktops                                                            **//

@media (min-width: $screen-tablet) {
  #main-navbar {


    // Navbar collapse
    //

    .main-navbar-collapse {
      background: none;
      border-top: none;
      @include box-shadow(none);
    }

    li {
      border-bottom: none;
    }

    // Dropdowns
    //

    .dropdown > a,
    .nav > li > a:hover {
      color: rgba(255,255,255,.7)
    }

    .dropdown.open > a,
    .dropdown.open > a:hover {
      background: rgba(0, 0, 0, 0.15);
      border: none;
      margin: 0;
      color: #fff;
    }

    .dropdown-menu {
      border-radius: $border-radius-base;
      margin-top: 3px;
      border-width: 2px;
      padding: 5px 0;

      li > a {
        color: $text-color;
        text-shadow: none;
        margin: 0 -2px;

        &:hover {
          background: $primary-color;
          color: #fff;
        }
      }
    }

    // Navbar buttons
    .nav-icon-btn > a > .label,
    .nav-icon-btn > a > .badge {
      @include box-shadow(0 1px 3px rgba(0,0,0,.1));
    }
  }
}


// Menu toggle borders
//

#main-navbar #main-menu-toggle,
&.right-to-left.main-menu-right #main-navbar #main-menu-toggle {
  border-right-width: 1px;
  @include box-shadow(1px 0 0 rgba(255,255,255,.2));
}

&.right-to-left #main-navbar #main-menu-toggle,
&.main-menu-right #main-navbar #main-menu-toggle {
  border-left-width: 1px;
  border-right-width: 0;
  @include box-shadow(1px 0 0 rgba(255,255,255,.2) inset);
}