//
// Themes / Frost / Main navbar
// --------------------------------------------------

$navbar-links-color: #555;
$navbar-links-border: #f1f1f1;

#main-navbar {
  @include box-shadow(0 2px 2px rgba(0,0,0,0.04),0 1px 0 rgba(0,0,0,0.04));


  // Header
  //

  .navbar-header {
    background: #fff;

    a {
      color: $navbar-links-color;
    }
  }


  // Collapse
  //

  .navbar-collapse {
    border-top: 1px solid $navbar-links-border;
    background: #fff;
    @include box-shadow(none);
  }

  .dropdown > a,
  .dropdown-menu > li > a,
  .nav > li > a {
    color: $navbar-links-color;
  }


  // Navbar buttons
  //

  .navbar-toggle {
    color: $navbar-links-color;
  }

  #main-menu-toggle {
    background: $navbar-color;
    color: #fff;
    @include box-sizing(content-box);

    .fa:before {
      color: #fff;
    }
  }

  // Navbar Items
  //

  li + li,
  .nav + .nav,
  .nav + .right {
    border-top: 1px solid $navbar-links-border;
  }

  .dropdown.open > a {
    border-bottom: 1px solid darken($navbar-links-border, 5%);
    border-top: 1px solid darken($navbar-links-border, 7%);
    margin-top: -1px;
    background: #f6f6f6;
  }


  // Navbar forms
  //

  form.navbar-form {
    @include box-shadow(none);

    .form-control {
      background: #fff;
      border-color: #ddd;
      border-radius: 999999px;
      @include transition(all .2s);

      &:focus {
        background: #fff;
        border-color: $navbar-color;
      }
    }
  }
}


// Desktops
//

@media (min-width: $screen-tablet) {
  #main-navbar {

    // Header
    //

    .navbar-header {
      background: $navbar-color;

      a {
        color: #fff;
      }
    }

    // Collapse
    //

    .navbar-collapse {
      background: #fff;
      border: none;
    }

    // Navbar button
    #main-menu-toggle {
      background: lighten($navbar-color, 5%);
    }


    // Navbar Items
    //

    li + li,
    .nav + .nav,
    .nav + .right {
      border-top: none;
    }


    // Dropdowns
    //

    .nav > li > a:hover {
      color: $text-color;
      background: #f8f8f8;
    }

    .dropdown.open > a {
      border-top: none;
      border-bottom: none;
      margin: 0;
      background: #f8f8f8;
      color: $text-color;
    }

    .dropdown-menu {
      border-top: none;
      margin-top: 1px;
      border-width: 2px;
      padding: 5px 0;

      li > a {
        color: $text-color;
        margin: 0 -2px;

        &:hover {
          background: $navbar-color;
          color: #fff;
        }
      }
    }


    // Notifications widget
    //

    .widget-notifications {
      a {
        color:  !important;
      }

      .notification,
      .notifications-link {
        border-color: $navbar-links-border;
        @include box-shadow(0 1px 0 rgba(255, 255, 255, 0.05) inset);
        @include border-bottom-radius(3px);
      }

      .notification-description {
        color: #999;
      }

      .notification-ago {
        color: #bbb;
      }

      .notifications-link:hover {
        background: $navbar-links-border;
      }
    }


    // Alt messages widget
    //

    .widget-messages-alt {
      a {
        color:  !important;
      }

      .message,
      .messages-link {
        border-color: $navbar-links-border;
        @include border-bottom-radius(3px);
      }

      .message-subject {
        color: $text-color !important;
      }

      .message-description {
        color: #aaa;

        a {
          color: #888;
        }
      }

      .messages-link:hover {
        background: $navbar-links-border;
      }
    }
  }

  // Collapsed menu
  &.mmc #main-navbar {
    #main-menu-toggle {
      background: $navbar-color;
    }

    .navbar-header {
      background: #fff;

      a {
        color: $navbar-links-color;
      }
    }
  }
}


// Main menu on the right
//

&.main-menu-right #main-navbar .navbar-header,
&.no-main-menu #main-navbar .navbar-header,
&.no-main-menu #main-navbar .navbar-header {
  background: #fff;

  a {
    color: $navbar-links-color;
  }
}
